
import React from "react"
import Head from '../components/Head'
import Layout from '../components/Layout'
import Button from "../components/basic/button"
import { StaticImage } from "gatsby-plugin-image"

const NewPage = () => {
    return (
        <Layout>
            <Head title="Executive Coaching Program at Saint Joseph’s University" description="The Executive and Team Coaching Certification Program in Philadelphia is designed to equip leaders with the skills to launch an executive coaching career."/>
            <div className='bg-executive-primary'>
                <div className='branding-container grid grid-cols-1 lg:grid-cols-2 gap-10 p-10 lg:pb-20 lg:pt-20 '>
                    <div className='space-y-10 text-white flex flex-col justify-center'>
                        <h1 className='branding-h1'>Executive & Team Coach Training</h1>
                        <h2 className='branding-subheader'>Taking your Coach Training to the Next Level</h2>
                        <p>As one of the most affordable executive coach training programs on the market, our Executive & Team Coach Training program is offered exclusively through Saint Joseph’s University. SJU has partnered with one of the premier coach training organizations in the nation, Coach Training EDU, to bring you industry-leading curriculum in foundational coach training, advanced coach training, and team coach training. This International Coaching Federation (ICF) certified program is designed for people who want an accelerated path toward a successful executive coaching career.</p>
                        <Button href='https://www.coachtrainingedu.com/training/' type='sju2' size='lg'>Attend a live free sample training</Button>
                    </div>
                    <div className='flex flex-col items-center justify-center'>
                        <StaticImage src='../images/Briefcase-head.png' style={{ maxmaxWidth: '500px' }}/>
                    </div>
                </div>
            </div>
            <div className='bg-executive-secondary'>
                <div className='branding-container p-10 lg:pb-20 lg:pt-20 text-white space-y-10'>
                    <div className='w-full text-center flex items-center justify-center'>
                        <h3 className='max-w-2xl branding-h3'>More than 75% of Coach Training EDU Graduates Report Earning Training-Related Income within Two Months of Course Completion.</h3>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-10'>
                        <div className='space-y-10 text-white flex flex-col justify-center items-center'>
                            <StaticImage src='../images/hand-car.png' style={{ maxWidth: '250px' }}/>
                            <h4 className='branding-h3'>Establish an Independent Practice</h4>
                            <p>Not only is this program full of advanced and team coaching training, but it comes with business building strategies and techniques to assist you in creating a successful coaching practice.</p>
                        </div>
                        <div className='space-y-10 text-white flex flex-col justify-center items-center'>
                            <StaticImage src='../images/hat-man.png' style={{ maxWidth: '250px' }}/>
                            <h4 className='branding-h3'>Take the Tools to Your Company</h4>
                            <p>ICF-credentialed coaches are in-demand, and many major companies are hiring. The training provided in this program will prepare you to bolster your company’s value and productivity.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='branding-container p-10 lg:pb-20 lg:pt-20 '>
                <div className='flex flex-col items-center justify-center space-y-10'>
                    <h3 className='max-w-2xl branding-h3'>Executive & Team Coach Training Overview</h3>
                    <div className='flex flex-col lg:flex-row items-center max-w-2xl space-y-10 lg:space-y-0 lg:space-x-10'>
                        <div style={{ maxWidth: '250px' }} ><StaticImage src='../images/race-car.png' /></div>
                        <div className='space-y-5 text-center lg:text-left'>
                            <h4 className='branding-h4'>Step 1: Foundational Coach Training</h4>
                            <p>Start the program by mastering the foundational tools and skills that every coach needs to start their coaching practice.</p>
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row items-center max-w-2xl space-y-10 lg:space-y-0 lg:space-x-10'>
                        <div style={{ maxWidth: '250px' }} ><StaticImage src='../images/tools.png'/></div>
                        <div className='space-y-5 text-center lg:text-left'>
                            <h4 className='branding-h4'>Step 2: Advanced Coach Training</h4>
                            <p>Dive more deeply into the research behind the program’s tools and skills. The advanced training builds on the foundational skills, giving you the ability to take your coaching practice to the next level.</p>
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row items-center max-w-2xl space-y-10 lg:space-y-0 lg:space-x-10'>
                        <div style={{ maxWidth: '250px' }} ><StaticImage src='../images/star-boy-girl.png' /></div>
                        <div className='space-y-5 text-center lg:text-left'>
                            <h4 className='branding-h4'>Step 3: Team Coaching</h4>
                            <p>The team coaching curriculum included in this program is exclusive to Saint Joseph’s University and adds a certification that will give you an edge over other executive coaches. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-executive-primary w-full'>
                <div className='branding-container flex flex-wrap flex-row items-center lg:justify-between p-10 lg:pb-20 lg:pt-20 space-y-10 lg:space-y-0'>
                    <div className='w-full lg:w-1/2 flex justify-center'>
                        <StaticImage src='../images/blue-shirt-girl.png' alt='Pricing' width={380}/>
                    </div>
                    <div className='w-full lg:w-1/2'>
                        <h3 className='branding-h3 lg:branding-h2 text-white mb-5'>Choose the plan that is right for you!</h3>
                        <h4 className='branding-subheader text-white mb-10'>Program Tuition is $9,600.</h4>
                        <div className='flex flex-row flex-wrap space-x-0 lg:space-x-5 space-y-10 lg:space-y-0'>
                            <div className='w-full lg:w-5/12 bg-white rounded-2xl text-center p-10 flex flex-col justify-between' style={{height: '400px'}}>
                                <h4 className='branding-h3'>Program Tuition</h4>
                                <div>
                                    <p>Pay in full and receive a</p>
                                    <p className='text-executive-primary font-sans text-1xl font-black'>10% DISCOUNT</p>
                                </div>
                                <div className='bg-black w-full' style={{height: '1px'}}/>
                                <div>
                                    <h5 className='font-serif text-5xl font-black'>$8,640</h5>
                                    <p>/ one time payment</p>
                                </div>
                                {/* <Button className=''/> */}
                            </div>
                            <div className='w-full lg:w-5/12 bg-white rounded-2xl text-center p-10 flex flex-col justify-between' style={{height: '400px'}}>
                                <h4 className='branding-h3'>Installment Plan</h4>
                                <p>4 Monthly Payments</p>
                                <div className='bg-black w-full' style={{height: '1px'}}/>
                                <div>
                                    <h5 className='font-serif text-5xl font-black'>$2,400</h5>
                                    <p>/ per month</p>
                                </div>
                                {/* <Button className=''/> */}
                            </div>
                        </div>
                        <h6 className='branding-h6 text-white mt-10'>*discounts available for Saint Joseph's University Alumni and Military please inquire.</h6>
                    </div>
                </div>
            </div>
            <div className='bg-executive-secondary'>
                <div className='branding-container p-10 lg:pb-20 lg:pt-20 text-white space-y-10'>
                    <h3 className='branding-h3 text-center'>Upcoming Courses</h3>
                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-10 text-center'>
                        <div className='flex flex-col items-center space-y-10'>
                            <StaticImage src='../images/calendar.png' style={{ maxWidth: '250px' }} />
                            <p className='text-xl'>Classes begin Tuesday, March 15th.</p>
                        </div>
                        <div className='flex flex-col items-center space-y-10'>
                            <StaticImage src='../images/high-five.png' style={{ maxWidth: '250px' }} />
                            <p className='text-xl'>Classes meet twice per week on Tuesdays and Thursdays.</p>
                        </div>
                        <div className='flex flex-col items-center space-y-10'>
                            <StaticImage src='../images/teaching-group.png' style={{ maxWidth: '250px' }} />
                            <p className='text-xl'>Cohorts are small to enhance learning.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-executive-primary'>
                <div className='branding-container p-10 lg:pb-20 lg:pt-20 text-white space-y-10'>
                    <h3 className='branding-h3 text-center'>Meet Your Trainers</h3>
                    <div className='grid grid-cols-1 gap-10'>
                        <div className='space-y-10 text-white flex flex-col justify-center items-center'>
                            <StaticImage src='../images/Britt-Fulmer.png' style={{ maxWidth: '300px' }}/>
                            <h4 className='branding-h3'>Britt Fulmer</h4>
                            <p className='max-w-lg text-center'>Britt is a CTEDU trainer, lead researcher,  training materials editor, Writing Team Lead and a member of the CTEDU Leadership Team. She is a former researcher and writer for the University of Pennsylvania and lives in Philadelphia, Pennsylvania.</p>
                        </div>
                        {/* <div className='space-y-10 text-white flex flex-col justify-center items-center'>
                            <StaticImage src='../images/Ashley.png' style={{ maxmaxWidth: '500px' }}/>
                            <h4 className='branding-h3'>Ashley</h4>
                            <p>...</p>
                        </div> */}
                    </div>
                </div>
                <div className='flex items-center justify-center pb-10 lg:pb-20'>
                    <Button to='/' type='sju2' size='lg'>Back to page 1</Button>
                </div>
            </div>
        </Layout>
    )
}

export default NewPage